/* LeelaMadhav Somu - 1002028333, 
     Dileep Kumar Naidu Ravi - 1002023397,
     Meet Savani - 10020232888   */

     
/* Styling the Body element i.e. Color,
Font, Alignment */
html{
  background-color: #fdf2e9;
  font-family: "Rubik", sans-serif;
  font-size: 62.5%;
  overflow-x: hidden;
  
  }
  .Heading{
    color: #cf711f;
    text-transform: capitalize;
    font-size: xx-large;
    text-align: center;
    margin: 30px;
  
  }
  

body {
  font-family: "Rubik", sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #555;
  overflow-x: hidden;
}

/* Styling the Form (Color, Padding, Shadow) */
.addprods {
  background-color: #fff;
  max-width: 500px;
  margin: 50px auto;
  padding: 30px 20px;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.5);
}
#label-name{
  font-size: 15px;
  font-weight: bold;
}

/* Styling form-control Class */
.form-control {
  text-align: left;
  margin-bottom: 25px;
}

/* Styling form-control Label */
.form-control label {
  display: block;
  margin-bottom: 10px;
}

/* Styling form-control input,
select, textarea */
.form-control input,
.form-control select,
.form-control textarea {
  border: 1px solid #777;
  border-radius: 2px;
  font-family: inherit;
  padding: 10px;
  display: block;
  width: 95%;
}

/* Styling form-control Radio
button and Checkbox */
.form-control input[type="radio"],
.form-control input[type="checkbox"] {
  display: inline-block;
  width: auto;
}

/* Styling Button */
.adsubmit {
  background-color: #cf711f;
  border: 1px solid #777;
  border-radius: 2px;
  font-family: inherit;
  font-size: 21px;
  display: block;
  width: 96%;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 8px 0px;
}
.login-signup{
  margin-top: 30px;
  text-align: center;
}
.stext{
  font-size: 16px;
}
body > div.login-signup > span > a{
  text-decoration: none ;
  color: #cf711f;
  font-weight: bold;
}
body > button > a{
  text-decoration: none;
  color: black;

}