.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.chat-icon-float {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 25px;
  right: 25px;
  height: 80px;
  width: 80px;
  border: none;
  border-radius: 180px;
  background-color: #cf711f;
  color: white;
  opacity: 0.8;
}

.white {
  color: white !important;
}

.chat-popup {
  display: none;
  position: fixed;
  bottom: 120px;
  right: 25px;
  border: 3px solid #e67e22;
  border-radius: 10px;
  z-index: 9;
  background-color: white;
  padding: 15px;
}

.form-container{
  /* min-height: 300px; */
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.form-container h1{
  color: #cf711f;
  font-size: 2.75rem;
}

.form-container div button{
height: 40px;
width: 100px;
background-color: #e67e22;
    color: #fff;
    padding: 10px;
    margin: 10px;
}

.form-container textarea{
  min-height: 80px;
}