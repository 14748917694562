/* LeelaMadhav Somu - 1002028333, 
     Dileep Kumar Naidu Ravi - 1002023397,
     Meet Savani - 10020232888   */

html{
    background-color: #fdf2e9;
  }
  .addprod {
      font-family: "Rubik", sans-serif;
  
    }
  
    .prod {
      text-align: center;
      font-size: 30px;
      margin: 0;
      padding-top: 10px;
    }
  /* 
    a {
      text-decoration: none;
    } */
  
    .gallerys {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  
    .cards {
      width: 24%;
      margin: 15px;
      box-sizing: border-box;
      float: left;
      text-align: center;
      border-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-top: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transition: .4s;
    }
  
    .cards:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, .2);
      transform: translate(0px, -8px);
      transition: .6s;
    }
  
    .displaypicture {
      width: 200px;
      height: 200px;
      text-align: center;
      margin: 0 auto;
      display: block;
    }
  
  .description {
      text-align: center;
      color: #b2bec3;
      padding: 0 8px;
      margin-top: 10px;
      font-size: 15px;
      min-height: 30px;
    }
  
    h6 {
      font-size: 26px;
      text-align: center;
      color: #222f3e;
      margin: 15px;
    } 
  
    .buy {
      text-align: center;
      font-size: 24px;
      color: #fff;
      width: 100%;
      padding: 15px;
      border: 0px;
      outline: none;
      cursor: pointer;
      margin-top: 5px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      background-color: #cf711f;
    }
  
    @media(max-width: 1000px) {
      .content {
        width: 46%;
      }
    }
  
    @media(max-width: 750px) {
      .content {
        width: 100%;
      }
    }