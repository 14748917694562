/* LeelaMadhav Somu - 1002028333, 
     Dileep Kumar Naidu Ravi - 1002023397,
     Meet Savani - 10020232888   */

/**************************/
/* HEADER */
/**************************/

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fdf2e9;

  /* Because we want header to be sticky later */
  height: 9.6rem;
  padding: 0 4.8rem;
  position: relative;
}

.logo {
  height: 2.2rem;
}

.title {
  color: #cf711f;
  text-decoration: underline #fdf2e9;
  text-transform: capitalize;
  font-size: xx-large;
}

.main-nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 4.8rem;
}

.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1.8rem;
  transition: all 0.3s;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: #cf711f;
}

.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
  padding: 1.2rem 2.4rem;
  border-radius: 9px;
  color: #fff;
  background-color: #e67e22;
}

.nav-cta{
  padding: 1.2rem 2.4rem;
  border-radius: 9px;
  color: #fff;
  background-color: #e67e22;
  font-weight: 500;
    font-size: 1.8rem;
}

.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
  background-color: #cf711f;
}

/* MOBILE */
.btn-mobile-navs {
  border: none;
  background: none;
  cursor: pointer;

  display: none;
}

.icon-mobile-nav {
  height: 4rem;
  width: 4rem;
  color: #333;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}

/* STICKY NAVIGATION */
.sticky .header {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 8rem;
  padding-top: 0;
  padding-bottom: 0;
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 999;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
}

.sticky .section-hero {
  margin-top: 9.6rem;
}
.sticky .header .title {
  text-decoration: underline rgba(255, 255, 255, 0.97);
}

.section-hero {
  background-color: #fdf2e9;
  padding: 9.8rem 0 9.6rem 0;
}

.hero {
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9.6rem;
  align-items: center;
}

.hero-description {
  font-size: 2rem;
  line-height: 1.6;
  margin-bottom: 4.8rem;
}

.hero-img {
  width: 100%;
  border-radius: 60%;
  transition: all 0.4s;
}

.hero-img:hover {
  transform: scale(1.1);
}

.delivered-meals {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-top: 8rem;
}

.delivered-imgs {
  display: flex;
}

.delivered-imgs img {
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 50%;
  margin-right: -1.6rem;
  border: 3px solid #fdf2e9;
}

.delivered-imgs img:last-child {
  margin: 0;
}

.delivered-text {
  font-size: 1.8rem;
  font-weight: 600;
}

.delivered-text span {
  color: #cf711f;
  font-weight: 700;
}

.section-featureds {
  background-color: #fdf2e9;
  display: grid;
  grid-template-columns: 55fr 45fr;
  align-items: center;
}

.featureds-container {
  padding: 9.6rem;
}

.featureds {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 4.8rem;
  column-gap: 8rem;
}

.featured-img {
  width: 6.4rem;
  border-radius: 50%;
  margin-bottom: 1.2rem;
}

.featured-text {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 1.6rem;
}

.featured-name {
  font-size: 1.6rem;
  color: #6f6f6f;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;
  padding: 1.6rem;
}

.gallery-item {
  overflow: hidden;
}

.gallery-item img {
  display: block;
  width: 100%;
  transition: all 0.4s;
}

.gallery-item img:hover {
  transform: scale(1.1);
}

.footer {
  /* padding: 12.8rem 0; */
  padding-top: 30px;
  border-top: 1px solid #eee;
  text-align: center;
  background-color: #fdf2e9;
}
.copyright {
  font-size: 1.8rem;
  line-height: 1.6;
  color: #767676;
  margin-top: auto;
}
