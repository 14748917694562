.about-layout {
  background-color: #fdf2e9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.full-page {
  min-height: 85vh;
  background-color: #fdf2e9;
}
.profile-background-color {
  margin: 10px;
}
.no-underline {
  text-decoration: none;
  color: #fff;
}
