/* LeelaMadhav Somu - 1002028333, 
     Dileep Kumar Naidu Ravi - 1002023397,
     Meet Savani - 10020232888   */


     
html{
    background-color: #fdf2e9;
  font-family: "Rubik", sans-serif;
  
    }
  .search {
    width: 100%;
    position: relative;
    display: flex;
  }
  
  .searchTerm {
    width: 100%;
    border: 3px solid #cf711f;
    border-right: none;
    padding: 5px;
    height: 36px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: #9DBFAF;
  }
  
  .searchTerm:focus{
    color: #cf711f;
  }
  
  .searchButton {
    width: 40px;
    height: 36px;
    border: 1px solid #cf711f;
    background: #cf711f;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
  }
  
  /*Resize the wrap to see the search bar change!*/
  .wrap{
  
    margin: 50px 440px;
  }

  .report-page-layout{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .report-page-text{
    font-size: 1.6rem;
    padding-bottom: 5px;
  }

  .report-box-layout{
    width: 80%;
    padding: 20px;
  }