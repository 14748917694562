/* LeelaMadhav Somu - 1002028333, 
     Dileep Kumar Naidu Ravi - 1002023397,
     Meet Savani - 10020232888   */

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #fdf2e9;
  font-family: "Roboto", sans-serif;
}

.shopping-cart {
  width: 800px;
  min-height: 460px;
  margin: 80px auto;
  background: #ffffff;
  box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.Heading {
  font-weight: bold;
  margin-bottom: 8px;
}
.cart-title {
  height: 60px;
  border-bottom: 1px solid #e1e8ee;
  padding: 20px 30px;
  color: #5e6977;
  font-size: 18px;
  font-weight: 400;
}

.item {
  padding: 20px 30px;
  height: 120px;
  display: flex;
  border-top: 1px solid #e1e8ee;
  border-bottom: 1px solid #e1e8ee;
}

/* .item:nth-child(3) {
  border-top: 1px solid #e1e8ee;
  border-bottom: 1px solid #e1e8ee;
} */
.buttons {
  position: relative;
  padding-top: 30px;
  margin-right: 60px;
}
.delete-btn,
.like-btn {
  display: inline-block;
  cursor: pointer;
}
.delete-btn {
  width: 18px;
  height: 17px;
  /* background: url(&amp;quot;delete-icn.svg&amp;quot;) no-repeat center; */
}

.like-btn {
  position: absolute;
  top: 9px;
  left: 15px;
  /* background: url('twitter-heart.png'); */
  width: 60px;
  height: 60px;
  background-size: 2900%;
  background-repeat: no-repeat;
}
.hide {
  display: none;
}
.is-active {
  animation-name: animate;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  animation-timing-function: steps(28);
  animation-fill-mode: forwards;
}

@keyframes animate {
  0% {
    background-position: left;
  }
  50% {
    background-position: right;
  }
  100% {
    background-position: right;
  }
}
.image {
  margin-right: 50px;
}

/* Let’s add some basic style to  product name and cart-description. */
.cart-description {
  padding-top: 10px;
  margin-right: 60px;
  width: 115px;
}

.cart-description span {
  display: block;
  font-size: 14px;
  color: #43484d;
  font-weight: 400;
}

.cart-description span:first-child {
  margin-bottom: 5px;
}
.cart-description span:last-child {
  font-weight: 300;
  margin-top: 15px;
  color: black;
  font-weight: bold;
}

.quantity {
  padding-top: 20px;
  margin-right: 60px;
}
.quantity input {
  -webkit-appearance: none;
  border: none;
  text-align: center;
  width: 32px;
  font-size: 16px;
  color: #43484d;
  font-weight: 300;
}

button[class*="btn"] {
  width: 30px;
  height: 30px;
  /* background-color: #e1e8ee; */
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
.minus-btn img {
  margin-bottom: 3px;
}
.plus-btn img {
  margin-top: 2px;
}

button:focus,
input:focus {
  outline: 0;
}
.total-price {
  width: 83px;
  text-align: center;
  font-size: 16px;
  color: #43484d;
  font-weight: 300;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-layout{
  display:flex ;
  align-items: center;
  justify-content: center;
  padding: 30px;
  
}


.manage-items{
 font-size: 1.4rem;
}

.flex-center{
  justify-self: center;
}

.margin-no{
  margin: auto;
  /* width: auto; */
  width: 100%;
  width: -webkit-fill-available;
}

.manage-delete-button{
  display: flex;
  text-align: center;
  /* font-size: 24px; */
  color: #fff;
  /* width: 100%; */
  padding: 15px;
  border: 0px;
  outline: none;
  cursor: pointer;
  margin-top: 5px;
  margin-left: auto;
  border-radius: 10px;
  /* border-bottom-right-radius: 10px; */
  /* border-bottom-left-radius: 10px; */
  background-color: #cf711f;
}

.no-items{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 50px;
}

.index-numbering{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-right: 20px;
}

@media (max-width: 800px) {
  .shopping-cart {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .item {
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
  }
  .image img {
    width: 50%;
  }
  .image,
  .quantity,
  .cart-description {
    width: 100%;
    text-align: center;
    margin: 6px 0;
  }
  .buttons {
    margin-right: 20px;
  }
}
